/** @jsx jsx */
import { jsx } from 'theme-ui'
import { Link, StaticQuery, graphql } from "gatsby"
import { RiArrowDownLine, RiArrowRightSLine } from "react-icons/ri"

import PostCard from "./post-card"

const PostMaker = ({ data }) => (
  <section className="home-posts">
    <h2>Advocacy Issues:
    <Link to="/5-world-trade-center">5 World Trade Center</Link>.<Link to="/charging-bull">Charging Bull</Link>.<Link to="/community-house">Downtown Community House</Link>.<Link to="/elizabeth-berger">Elizabeth Berger Plaza</Link>.<Link to="/heritage-trails">Heritage Trails New York</Link>.<Link to="/lower-west-side">Lower West Side Preservation</Link>.<Link to="/percent-for-art">Percent for Art Reform</Link>.<Link to="/project-khalid">Project Khalid and Ameen Rihani</Link>.<Link to="/rector-street">Rector Street Mosque</Link>.<Link to="/museum">September 11 Museum</Link>.<Link to="/monument-locations">SheBuiltNYC and Monument Siting</Link>.<Link to="/soho-noho">SoHo NoHo Rezoning</Link>.<Link to="/south-street-seaport">South Street Seaport</Link>.<Link to="/joseph-chapel">St. Joseph's Chapel</Link>.<Link to="/suffrage-monument">Suffrage Monument in Central Park</Link>.<Link to="/yuga-labs">Yuga Labs and Bored Ape Yacht Club</Link>
    </h2>
    <h2>Latest in <strong>Blog</strong> <span class="icon -right"><RiArrowDownLine/></span></h2>
    <div className="grids col-1 sm-2 lg-3">
      {data}
    </div>
    <Link
      className="button"
      to="/blog"
      sx={{
        variant: 'links.button'
      }}
    >
      See more<span class="icon -right"><RiArrowRightSLine/></span>
    </Link>
  </section>
)

export default function BlogListHome() {
  return (
    <StaticQuery
      query={graphql`
        query {
          allMarkdownRemark(
            sort: { order: DESC, fields: [frontmatter___date] }
            filter: { frontmatter: { template: { eq: "blog-post" } } }
            limit: 6
          ) {
            edges {
              node {
                id
                excerpt(pruneLength: 250)
                frontmatter {
                  date(formatString: "MMMM DD, YYYY")
                  slug
                  title
                  featuredImage {
                    childImageSharp {
                      fluid(maxWidth: 540, maxHeight: 360, quality: 80) {
                        ...GatsbyImageSharpFluid
                        ...GatsbyImageSharpFluidLimitPresentationSize
                      }
                    }
                  }
                }
              }
            }
          }
        }`
      }

      render={ data => {
          const posts = data.allMarkdownRemark.edges
            .filter(edge => !!edge.node.frontmatter.date)
            .map(edge =>
              <PostCard key={edge.node.id} data={edge.node} />
          )
          return <PostMaker data={posts} />
        }
      }
    />
  )
}
